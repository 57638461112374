var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-sm-6 col-md-6 col-lg-6 mb-3"},[_c('a-button',{staticClass:"btn btn-outline-primary float-right",on:{"click":function($event){return _vm.sendToForm('Create', {})}}},[_vm._v("Tambah")])],1),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12"},[_c('a-table',{staticStyle:{"margin-left":"-26px","margin-right":"-26px"},attrs:{"columns":_vm.columns,"dataSource":_vm.datatable,"size":"small","pagination":{
                  defaultPageSize: 10,
                  showQuickJumper: true,
                  showSizeChanger: true,
                  showTotal: (total) => `Total ${total} items`,
                  pageSizeOptions: ['10', '20', '30'],
                }},scopedSlots:_vm._u([{key:"filterDropdown",fn:function({
                    setSelectedKeys,
                    selectedKeys,
                    confirm,
                    clearFilters,
                    column,
                  }){return _c('div',{staticStyle:{"padding":"8px"}},[_c('a-input',{directives:[{name:"ant-ref",rawName:"v-ant-ref",value:((c) => (_vm.searchInput = c)),expression:"(c) => (searchInput = c)"}],staticStyle:{"width":"188px","margin-bottom":"8px","display":"block"},attrs:{"placeholder":`Search ${column.dataIndex}`,"value":selectedKeys[0]},on:{"change":(e) =>
                        setSelectedKeys(
                          e.target.value ? [e.target.value] : []
                        ),"pressEnter":() =>
                        _vm.handleSearch(selectedKeys, confirm, column.dataIndex)}}),_c('a-button',{staticStyle:{"width":"90px","margin-right":"8px"},attrs:{"type":"primary","icon":"search","size":"small"},on:{"click":() =>
                        _vm.handleSearch(selectedKeys, confirm, column.dataIndex)}},[_vm._v(" Search ")]),_c('a-button',{staticStyle:{"width":"90px"},attrs:{"size":"small"},on:{"click":() => _vm.handleReset(clearFilters)}},[_vm._v(" Reset ")])],1)}},{key:"filterIcon",fn:function(filtered){return _c('a-icon',{style:({ color: filtered ? '#108ee9' : undefined }),attrs:{"type":"search"}})}},{key:"customRender",fn:function(text, record, index, column){return [(_vm.searchText && _vm.searchedColumn === column.dataIndex)?_c('span',[_vm._l((text
                        .toString()
                        .split(
                          new RegExp(
                            `(?<=${_vm.searchText})|(?=${_vm.searchText})`,
                            'i'
                          )
                        )),function(fragment,i){return [(
                          fragment.toLowerCase() === _vm.searchText.toLowerCase()
                        )?_c('mark',{key:i,staticClass:"highlight"},[_vm._v(_vm._s(fragment))]):[_vm._v(_vm._s(fragment))]]})],2):[_vm._v(" "+_vm._s(text)+" ")]]}},{key:"expandedRowRender",fn:function(record){return [_c('a-table',{attrs:{"bordered":true,"columns":_vm.childColumns,"data-source":record.jam_kerja,"size":"small","pagination":{
                      hideOnSinglePage: true,
                      defaultPageSize: 10,
                      showQuickJumper: true,
                      showSizeChanger: true,
                      showTotal: (total) => `Total ${total} items`,
                      pageSizeOptions: ['10', '20', '30'],
                    }}})]}},{key:"jumlah_jamkerja",fn:function(text, record){return _c('span',{},[_vm._v(" "+_vm._s(record.jam_kerja.length)+" ")])}},{key:"action",fn:function(text, record, index){return _c('span',{},[_c('a',{staticClass:"text-warning",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.sendToForm('Update', record)}}},[_vm._v("Edit")]),(index !== 0)?_c('a-divider',{attrs:{"type":"vertical"}}):_vm._e(),(index !== 0)?_c('a',{staticClass:"text-danger",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.showDeleteConfirm(record)}}},[_vm._v("Delete")]):_vm._e()],1)}}])})],1)])])])])]),_c('popup-form',{key:_vm.componentKey,ref:"popupform"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6 col-md-6 col-lg-6 mb-3"},[_c('strong',[_vm._v("Shift")])])
}]

export { render, staticRenderFns }
<template>
  <div>
    <a-modal
      v-model="visible"
      :title="action + ' Shift'"
      :dialogStyle="{ top: '10px' }"
      :destroyOnClose="true"
      :forceRender="true"
    >
      <template slot="footer">
        <a-button class="btn btn-outline-danger" @click="handleCancel">
          Cancel
        </a-button>
        <a-button
          class="btn btn-outline-primary"
          :loading="loading"
          @click="submit()"
        >
          Simpan
        </a-button>
      </template>
      <div class="row">
        <div class="col-sm-3 col-md-3 col-lg-3 mb-2">
          <label class="mt-1">Name</label>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-9 mb-2">
          <a-input
            placeholder="Masukan nama jam kerja"
            v-model="input.name"
          ></a-input>
        </div>
        <div class="col-sm-3 col-md-3 col-lg-3 mb-2">
          <label class="mt-1">Nama shift</label>
        </div>
        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mb-2">
          <a-auto-complete
            v-model="input.shift"
            :data-source="dataSource"
            placeholder="Input name shift"
            @search="onSearch"
          />
        </div>
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mb-2">
          <label class="mt-1">Pilih hari</label>
        </div>
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mb-2">
          <a-select
            show-search
            placeholder="Pilih hari"
            option-filter-prop="children"
            v-model="input.hari"
            :filter-option="filterOption"
            @focus="handleFocus"
            @blur="handleBlur"
            @change="handleChange"
          >
            <a-select-option value="senin">
              Senin
            </a-select-option>
            <a-select-option value="selasa">
              Selasa
            </a-select-option>
            <a-select-option value="rabu">
              Rabu
            </a-select-option>
            <a-select-option value="kamis">
              Kamis
            </a-select-option>
            <a-select-option value="jumat">
              Jum'at
            </a-select-option>
            <a-select-option value="sabtu">
              Sabtu
            </a-select-option>
            <a-select-option value="minggu">
              Minggu
            </a-select-option>
          </a-select>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mb-2">
          <label class="mt-1">Range shift</label>
        </div>
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mb-3">
          <a-time-picker
            :default-value="moment()"
            format="HH:mm"
            style="width: 70px;"
            placeholder="Dari"
            v-model="input.datang"
          />
        </div>
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
          <a-time-picker
            :default-value="moment()"
            format="HH:mm"
            style="width: 70px;"
            placeholder="Sampai"
            v-model="input.pulang"
          />
        </div>
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
          <a-button class="btn btn-outline-primary" @click="addshift">
            <i class="fa fa-save mr-2" v-if="editshiftmode"></i>
            <i class="fa fa-plus mr-2" v-else></i>
            Shift
          </a-button>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <a-table
            :columns="columns"
            :dataSource="datatable"
            style="margin-left: -24px; margin-right: -24px;"
            size="small"
            :pagination="{
              showQuickJumper: true,
              showSizeChanger: true,
              hideOnSinglePage: true,
              defaultPageSize: 10,
              showTotal: (total) => `Total ${total} items`,
              pageSizeOptions: ['10', '20', '30'],
            }"
          >
            <template slot="action" slot-scope="text, record, index">
              <i
                class="fa fa-pencil text-warning"
                @click="editshift(index, record)"
              ></i>
              <a-divider type="vertical" />
              <i
                class="fa fa-trash text-danger"
                @click="deleteshift(index)"
              ></i>
            </template>
          </a-table>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'

export default {
  data() {
    return {
      action: 'Create',
      editdata: [],
      input: {
        name: '',
        editindex: null,
        shift: null,
        hari: 'senin',
        datang: null,
        pulang: null,
      },
      editshiftmode: false,
      columns: [
        {
          title: 'Shift',
          dataIndex: 'shift',
          scopedSlots: {
            customRender: 'shift',
          },
        },
        {
          title: 'Hari',
          dataIndex: 'hari',
          scopedSlots: {
            customRender: 'hari',
          },
        },
        {
          title: 'Datang',
          dataIndex: 'datang',
          scopedSlots: {
            customRender: 'datang',
          },
        },
        {
          title: 'Pulang',
          dataIndex: 'pulang',
          scopedSlots: {
            customRender: 'pulang',
          },
        },
        {
          title: 'Opsi',
          dataIndex: '#',
          scopedSlots: {
            customRender: 'action',
          },
          align: 'center',
        },
      ],
      datatable: [],
      dataSource: [],
      visible: false,
      loading: false,
      datenow: '',
    }
  },
  created() {
    this.input.datang = moment()
    this.input.pulang = moment().add(5, 'hours')
    this.datenow = lou.datenow()
  },
  methods: {
    moment,
    async submit() {
      if (this.datatable.length !== 0) {
        this.loading = true
        var fd = {
          name: this.input.name,
          jadwal: this.datatable,
        }
        if (this.action === 'Create') {
          await lou.createMaster('role', fd)
        } else {
          fd.id = this.editdata.id
          await lou.updateMaster('role', fd)
        }
        this.loading = false
        this.$parent.getAllData()
        this.handleCancel()
      } else {
        lou.shownotif('Tidak valid', 'Data shift tidak boleh kosong!')
      }
    },
    showModal(action, data) {
      this.editdata = this.$g.clone(data)
      if (action !== 'Create') {
        console.log("moment().add(4, 'hours').format('HH:mm')", moment().add(4, 'hours').format('HH:mm'))
        this.input = {
          name: this.editdata.name,
          editindex: null,
          shift: '',
          hari: 'senin',
          datang: moment(),
          pulang: moment().add(4, 'hours'),
        }
        this.editshiftmode = false
        this.datatable = this.editdata.jam_kerja
      } else {
        this.resetForm()
      }
      this.visible = true
      this.action = action
    },
    addshift() {
      if (
        this.input.shift === null ||
        this.input.hari === null ||
        this.input.datang === null ||
        this.input.pulang === null
      ) {
        lou.shownotif('Input shift, hari, datang, atau pulang masih kosong')
      } else {
        var data = {
          shift: this.input.shift,
          hari: this.input.hari,
          datang: this.input.datang.format('HH:mm'),
          pulang: this.input.pulang.format('HH:mm'),
        }
        if (this.editshiftmode) {
          this.datatable[this.input.editindex] = data
          this.editshiftmode = false
          this.input.editindex = null
        } else {
          this.datatable.push(data)
        }
        this.input.shift = ''
        this.input.hari = 'senin'
      }
    },
    editshift(index, record) {
      this.input.shift = record.shift
      this.input.hari = record.hari
      this.input.datang = moment(record.datang, 'HH:mm')
      this.input.pulang = moment(record.pulang, 'HH:mm')
      this.input.editindex = index
      this.editshiftmode = true
    },
    deleteshift(index) {
      console.log('index', index)
      this.datatable.splice(index, 1)
    },
    handleCancel() {
      this.visible = false
      this.cleanForm()
    },
    resetForm() {
      this.action = 'Create'
      this.editdata = []
      this.input = {
        name: '',
        editindex: null,
        shift: null,
        hari: 'senin',
        datang: moment(),
        pulang: moment().add(5, 'hours'),
      }
      this.datatable = []
      this.editshiftmode = false
      this.visible = false
      this.loading = false
      this.datenow = ''
    },
    onSearch(searchText) {
      this.dataSource = !searchText ? [] : ['Pagi', 'Sore', 'Damn']
    },
    handleChange(value) {
      console.log(`selected ${value}`)
    },
    handleBlur() {
      console.log('blur')
    },
    handleFocus() {
      console.log('focus')
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    capt(word) {
      return lou.capitalize(word)
    },
  },
}
</script>

<style></style>
